var render = function () {
  var _vm$salesReport$sales, _vm$salesReport, _vm$salesReport$goal, _vm$salesReport2, _vm$salesReport$goal_, _vm$salesReport3, _vm$salesReport$cash_, _vm$salesReport4, _vm$costsReport$labor, _vm$costsReport, _vm$costsReport$labor2, _vm$costsReport2, _vm$costsReport$platf, _vm$costsReport3, _vm$costsReport$platf2, _vm$costsReport4, _vm$costsReport$costs, _vm$costsReport5, _vm$costsReport$foods, _vm$costsReport6, _vm$costsReport$food_, _vm$costsReport7, _vm$costsReport$other, _vm$costsReport8, _vm$costsReport$other2, _vm$costsReport9, _vm$salesReport$sales2, _vm$salesReport5, _vm$costsReport$costs2, _vm$costsReport10, _vm$costsReport$rent_, _vm$costsReport11, _vm$costsReport$rent_2, _vm$costsReport12;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-Roboto"
  }, [_c('Parent'), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "pt-3 d-flex pb-3"
  }, [_c('v-card', {
    staticClass: "ml-4",
    attrs: {
      "color": "#525252",
      "flat": "",
      "height": "32px",
      "width": "118px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center title-card"
  }, [_c('v-icon', {
    staticClass: "icon-adjust"
  }, [_vm._v("$whiteMonthlyReport")]), _c('div', {
    staticClass: "font-18px white--text ml-2"
  }, [_vm._v(" 月報一覧 ")])], 1)]), _c('div', {
    staticClass: "dropdown-main-div mx-4 ml-3"
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "solo": "",
      "flat": "",
      "items": _vm.years,
      "hide-details": "",
      "append-icon": "$purpleDownArrow",
      "background-color": "#D8D5FC",
      "height": "29"
    },
    on: {
      "change": _vm.getDataFromApi
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "primary--text pr-4 font-16px"
        }, [_vm._v(_vm._s("".concat(item.period, "\u5E74\u5EA6")))]), _c('span', {
          staticClass: "primary--text font-14px"
        }, [_vm._v(" " + _vm._s("".concat(item.begin, " \uFF5E ").concat(item.end)) + " ")])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "primary--text pr-4 font-16px"
        }, [_vm._v(_vm._s("".concat(item.period, "\u5E74\u5EA6")))]), _c('span', {
          staticClass: "primary--text font-14px"
        }, [_vm._v(" " + _vm._s("".concat(item.begin, " \uFF5E ").concat(item.end)) + " ")])];
      }
    }]),
    model: {
      value: _vm.selectedYear,
      callback: function callback($$v) {
        _vm.selectedYear = $$v;
      },
      expression: "selectedYear"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-3 divider"
  }), _c('div', {
    staticClass: "d-flex ml-14 mt-3 font-Roboto"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center pl-3"
  }, [_vm._v("総売上")]), _c('div', {
    staticClass: "ml-5 font-18px font-Roboto-Medium text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$salesReport$sales = (_vm$salesReport = _vm.salesReport) === null || _vm$salesReport === void 0 ? void 0 : _vm$salesReport.sales) !== null && _vm$salesReport$sales !== void 0 ? _vm$salesReport$sales : 0)) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center text-light-blue"
  }, [_vm._v(" 目標売上 ")]), _c('div', {
    staticClass: "ml-5 font-14px text-52 text-light-blue"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$salesReport$goal = (_vm$salesReport2 = _vm.salesReport) === null || _vm$salesReport2 === void 0 ? void 0 : _vm$salesReport2.goal) !== null && _vm$salesReport$goal !== void 0 ? _vm$salesReport$goal : 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-3 d-flex px-2 font-12px text-light-blue chip-class",
    attrs: {
      "color": "rgba(0, 148, 255, 0.15)"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$salesReport$goal_ = (_vm$salesReport3 = _vm.salesReport) === null || _vm$salesReport3 === void 0 ? void 0 : _vm$salesReport3.goal_percentage) !== null && _vm$salesReport$goal_ !== void 0 ? _vm$salesReport$goal_ : 0)) + "% ")])], 1)]), _c('div', {
    staticClass: "ml-14"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52"
  }, [_vm._v("( 現金売上 )")]), _c('div', {
    staticClass: "ml-5 font-18px font-Roboto-Medium text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$salesReport$cash_ = (_vm$salesReport4 = _vm.salesReport) === null || _vm$salesReport4 === void 0 ? void 0 : _vm$salesReport4.cash_sales) !== null && _vm$salesReport$cash_ !== void 0 ? _vm$salesReport$cash_ : 0)) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-4"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" 人件費 ")]), _c('div', {
    staticClass: "ml-8 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$costsReport$labor = (_vm$costsReport = _vm.costsReport) === null || _vm$costsReport === void 0 ? void 0 : _vm$costsReport.labor_cost) !== null && _vm$costsReport$labor !== void 0 ? _vm$costsReport$labor : 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex px-2 font-12px text-58 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s((_vm$costsReport$labor2 = (_vm$costsReport2 = _vm.costsReport) === null || _vm$costsReport2 === void 0 ? void 0 : _vm$costsReport2.labor_cost_percentage) !== null && _vm$costsReport$labor2 !== void 0 ? _vm$costsReport$labor2 : 0) + "% ")])], 1), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-4"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" PF手数料 ")]), _c('div', {
    staticClass: "ml-5 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$costsReport$platf = (_vm$costsReport3 = _vm.costsReport) === null || _vm$costsReport3 === void 0 ? void 0 : _vm$costsReport3.platform_cost) !== null && _vm$costsReport$platf !== void 0 ? _vm$costsReport$platf : 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex px-2 font-12px text-58 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s((_vm$costsReport$platf2 = (_vm$costsReport4 = _vm.costsReport) === null || _vm$costsReport4 === void 0 ? void 0 : _vm$costsReport4.platform_cost_percentage) !== null && _vm$costsReport$platf2 !== void 0 ? _vm$costsReport$platf2 : 0) + "% ")])], 1)]), _c('div', {
    staticClass: "ml-10"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52"
  }, [_vm._v("総コスト")]), _c('div', {
    staticClass: "ml-5 font-18px font-Roboto-Medium  text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$costsReport$costs = (_vm$costsReport5 = _vm.costsReport) === null || _vm$costsReport5 === void 0 ? void 0 : _vm$costsReport5.costs) !== null && _vm$costsReport$costs !== void 0 ? _vm$costsReport$costs : 0)) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" 食材費 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$costsReport$foods = (_vm$costsReport6 = _vm.costsReport) === null || _vm$costsReport6 === void 0 ? void 0 : _vm$costsReport6.foodstuff_cost) !== null && _vm$costsReport$foods !== void 0 ? _vm$costsReport$foods : 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex font-12px text-58 px-2 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s((_vm$costsReport$food_ = (_vm$costsReport7 = _vm.costsReport) === null || _vm$costsReport7 === void 0 ? void 0 : _vm$costsReport7.food_cost_percentage) !== null && _vm$costsReport$food_ !== void 0 ? _vm$costsReport$food_ : 0) + "% ")])], 1), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" その他 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$costsReport$other = (_vm$costsReport8 = _vm.costsReport) === null || _vm$costsReport8 === void 0 ? void 0 : _vm$costsReport8.others_cost) !== null && _vm$costsReport$other !== void 0 ? _vm$costsReport$other : 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex font-12px text-58 px-2 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s((_vm$costsReport$other2 = (_vm$costsReport9 = _vm.costsReport) === null || _vm$costsReport9 === void 0 ? void 0 : _vm$costsReport9.others_cost_percentage) !== null && _vm$costsReport$other2 !== void 0 ? _vm$costsReport$other2 : 0) + "% ")])], 1)]), _c('div', {
    staticClass: "ml-10"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52"
  }, [_vm._v("損益")]), _c('div', {
    staticClass: "ml-5 font-18px font-Roboto-Medium  text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$salesReport$sales2 = (_vm$salesReport5 = _vm.salesReport) === null || _vm$salesReport5 === void 0 ? void 0 : _vm$salesReport5.sales) !== null && _vm$salesReport$sales2 !== void 0 ? _vm$salesReport$sales2 : 0) - ((_vm$costsReport$costs2 = (_vm$costsReport10 = _vm.costsReport) === null || _vm$costsReport10 === void 0 ? void 0 : _vm$costsReport10.costs) !== null && _vm$costsReport$costs2 !== void 0 ? _vm$costsReport$costs2 : 0))) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" 家賃 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$costsReport$rent_ = (_vm$costsReport11 = _vm.costsReport) === null || _vm$costsReport11 === void 0 ? void 0 : _vm$costsReport11.rent_cost) !== null && _vm$costsReport$rent_ !== void 0 ? _vm$costsReport$rent_ : 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex font-12px text-58 px-2 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s((_vm$costsReport$rent_2 = (_vm$costsReport12 = _vm.costsReport) === null || _vm$costsReport12 === void 0 ? void 0 : _vm$costsReport12.rent_cost_percentage) !== null && _vm$costsReport$rent_2 !== void 0 ? _vm$costsReport$rent_2 : 0) + "% ")])], 1)])]), _c('Table', {
    staticClass: "mt-5",
    attrs: {
      "disable-pagination": "",
      "headers": _vm.tableHeaders,
      "items": _vm.monthlyReports,
      "total-records": _vm.monthlyReports.length,
      "footer": false
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item,
            headers = _ref3.headers,
            index = _ref3.index;
        return [_c('tr', [_vm._l(_vm.tableHeaders.filter(function (v, j) {
          return j !== _vm.tableHeaders.length - 1;
        }), function (header, i) {
          return _c('td', {
            key: "monthly-report-item-".concat(index, "-").concat(i),
            staticClass: "text-center"
          }, [header.type === 'money' ? [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.showValue(header, item))) + " ")] : header.type === 'percentage' ? [_c('div', {
            "class": _vm.showValue(header, item) > 100 ? 'text-green' : _vm.showValue(header, item) !== '--' ? 'text-red' : ''
          }, [_vm._v(" " + _vm._s(_vm.showValue(header, item)) + " "), _vm.showValue(header, item) !== '--' ? [_vm._v(" % ")] : _vm._e()], 2)] : header.type === 'money-percentage' ? [_c('div', {
            "class": _vm.showValue(header, item) > 100 ? 'text-green' : _vm.showValue(header, item) !== '--' ? 'text-red' : ''
          }, [_vm._v(" " + _vm._s(_vm.showValue(header, item)) + "% ")]), _c('div', {
            staticClass: "text-bbb"
          }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.showValue(header, item, "extraValue"))) + " ")])] : [_vm._v(" " + _vm._s(_vm.showValue(header, item)) + " ")]], 2);
        }), _c('td', [_c('v-icon', {
          attrs: {
            "size": "15"
          },
          on: {
            "click": function click($event) {
              _vm.$router.push({
                name: 'MonthlyReportDetail',
                params: {
                  month: _vm.getFormattedDate(item.date)
                }
              });
            }
          }
        }, [_vm._v(" $arrowRight ")])], 1)], 2)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }