<template>
  <div class="font-Roboto">
    <Parent />
    <v-card class="mt-3">
      <div class="pt-3 d-flex pb-3">
        <v-card color="#525252" flat height="32px" width="118px" class="ml-4">
          <div class="d-flex justify-center align-center title-card">
            <v-icon class="icon-adjust">$whiteMonthlyReport</v-icon>
            <div class="font-18px white--text ml-2">
              月報一覧
            </div>
          </div>
        </v-card>
        <div class="dropdown-main-div mx-4 ml-3">
          <v-select
            dense
            solo
            flat
            :items="years"
            hide-details
            append-icon="$purpleDownArrow"
            background-color="#D8D5FC"
            v-model="selectedYear"
            @change="getDataFromApi"
            height="29"
          >
            <template v-slot:item="{ item }">
              <span class="primary--text pr-4 font-16px">{{
                `${item.period}年度`
              }}</span>
              <span class="primary--text font-14px">
                {{ `${item.begin} ～ ${item.end}` }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="primary--text pr-4 font-16px">{{
                `${item.period}年度`
              }}</span>
              <span class="primary--text font-14px">
                {{ `${item.begin} ～ ${item.end}` }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="mt-3 divider"></div>
      <div class="d-flex ml-14 mt-3 font-Roboto">
        <div>
          <div class="d-flex align-center">
            <div class="font-12px text-52 text-center pl-3">総売上</div>
            <div class="ml-5 font-18px font-Roboto-Medium text-52">
              {{ salesReport?.sales ?? 0 | formatMoney }}
            </div>
          </div>
          <div class="d-flex align-center mt-2">
            <div class="font-12px text-52 text-center text-light-blue">
              目標売上
            </div>
            <div class="ml-5 font-14px text-52 text-light-blue">
              {{ salesReport?.goal ?? 0 | formatMoney }}
            </div>
            <v-chip
              class="ml-3 d-flex px-2 font-12px text-light-blue chip-class"
              color="rgba(0, 148, 255, 0.15)"
            >
              {{ salesReport?.goal_percentage ?? 0 | formatMoney }}%
            </v-chip>
          </div>
        </div>

        <div class="ml-14">
          <div class="d-flex align-center">
            <div class="font-12px text-52">( 現金売上 )</div>
            <div class="ml-5 font-18px font-Roboto-Medium text-52">
              {{ salesReport?.cash_sales ?? 0 | formatMoney }}
            </div>
          </div>
          <div class="d-flex align-center mt-2 ml-4">
            <div class="font-12px text-52 text-center">
              人件費
            </div>
            <div class="ml-8 font-14px text-52">
              {{ costsReport?.labor_cost ?? 0 | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex px-2 font-12px text-58 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.labor_cost_percentage ?? 0 }}%
            </v-chip>
          </div>
          <div class="d-flex align-center mt-2 ml-4">
            <div class="font-12px text-52 text-center">
              PF手数料
            </div>
            <div class="ml-5 font-14px text-52">
              {{ costsReport?.platform_cost ?? 0 | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex px-2 font-12px text-58 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.platform_cost_percentage ?? 0 }}%
            </v-chip>
          </div>
        </div>

        <div class="ml-10">
          <div class="d-flex align-center">
            <div class="font-12px text-52">総コスト</div>
            <div class="ml-5 font-18px font-Roboto-Medium  text-52">
              {{ costsReport?.costs ?? 0 | formatMoney }}
            </div>
          </div>
          <div class="d-flex align-center mt-2 ml-2">
            <div class="font-12px text-52 text-center">
              食材費
            </div>
            <div class="ml-4 font-14px text-52">
              {{ costsReport?.foodstuff_cost ?? 0 | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex font-12px text-58 px-2 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.food_cost_percentage ?? 0 }}%
            </v-chip>
          </div>
          <div class="d-flex align-center mt-2 ml-2">
            <div class="font-12px text-52 text-center">
              その他
            </div>
            <div class="ml-4 font-14px text-52">
              {{ costsReport?.others_cost ?? 0 | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex font-12px text-58 px-2 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.others_cost_percentage ?? 0 }}%
            </v-chip>
          </div>
        </div>
        <div class="ml-10">
          <div class="d-flex align-center">
            <div class="font-12px text-52">損益</div>
            <div class="ml-5 font-18px font-Roboto-Medium  text-52">
              {{
                ((salesReport?.sales ?? 0) - (costsReport?.costs ?? 0))
                  | formatMoney
              }}
            </div>
          </div>
          <div class="d-flex align-center mt-2">
            <div class="font-12px text-52 text-center">
              家賃
            </div>
            <div class="ml-4 font-14px text-52">
              {{ costsReport?.rent_cost ?? 0 | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex font-12px text-58 px-2 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.rent_cost_percentage ?? 0 }}%
            </v-chip>
          </div>
        </div>
      </div>
      <Table
        class="mt-5"
        disable-pagination
        :headers="tableHeaders"
        :items="monthlyReports"
        :total-records="monthlyReports.length"
        :footer="false"
      >
        <template v-slot:item="{ item, headers, index }">
          <tr>
            <template>
              <td
                class="text-center"
                v-for="(header, i) in tableHeaders.filter(
                  (v, j) => j !== tableHeaders.length - 1
                )"
                :key="`monthly-report-item-${index}-${i}`"
              >
                <template v-if="header.type === 'money'">
                  {{ showValue(header, item) | formatMoney }}
                </template>
                <template v-else-if="header.type === 'percentage'">
                  <div
                    :class="
                      showValue(header, item) > 100
                        ? 'text-green'
                        : showValue(header, item) !== '--'
                        ? 'text-red'
                        : ''
                    "
                  >
                    {{ showValue(header, item) }}
                    <template v-if="showValue(header, item) !== '--'">
                      %
                    </template>
                  </div>
                </template>
                <template v-else-if="header.type === 'money-percentage'">
                  <div
                    :class="
                      showValue(header, item) > 100
                        ? 'text-green'
                        : showValue(header, item) !== '--'
                        ? 'text-red'
                        : ''
                    "
                  >
                    {{ showValue(header, item) }}%
                  </div>
                  <div class="text-bbb">
                    {{ showValue(header, item, "extraValue") | formatMoney }}
                  </div>
                </template>
                <template v-else>
                  {{ showValue(header, item) }}
                </template>
              </td>
            </template>
            <td>
              <v-icon
                @click="
                  $router.push({
                    name: 'MonthlyReportDetail',
                    params: { month: getFormattedDate(item.date) }
                  })
                "
                size="15"
              >
                $arrowRight
              </v-icon>
            </td>
          </tr>
        </template>
      </Table>
    </v-card>
  </div>
</template>

<script>
import Parent from "@/views/admin/Store/Index";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
import Table from "@/components/admin/partials/Table/Table";

export default {
  name: "MonthlyReportList",
  components: { Table, Parent },
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters([
      "getShop",
      "getShopMonthlyReports",
      "getShopYearlyCumulatedSalesReport",
      "getShopYearlyCumulatedCostsReport"
    ]),
    salesReport() {
      return this.getShopYearlyCumulatedSalesReport;
    },
    costsReport() {
      return this.getShopYearlyCumulatedCostsReport;
    },
    monthlyReports() {
      return this.getShopMonthlyReports.map(report => {
        return {
          month_formatted: report.monthFormatted,
          ...report.sales,
          ...report.costs,
          profit_or_loss:
            (report.sales?.sales ?? 0) - (report.costs?.costs ?? 0) || null
        };
      });
    },
    currentFiscalYear() {
      let now = dayjs();
      let month = now.month();
      let fiscalYearMonth = this.getShop.start_of_fiscal_year - 1;
      let beginFiscalYear = null;
      let endFiscalYear = null;
      let currentFiscalYear = null;

      if (month < fiscalYearMonth) {
        beginFiscalYear = now.subtract(1, "year").month(fiscalYearMonth);
      } else {
        beginFiscalYear = now.month(fiscalYearMonth);
      }
      currentFiscalYear = beginFiscalYear.format("YYYY");
      endFiscalYear = beginFiscalYear.add(1, "year").subtract(1, "month");

      return {
        year: currentFiscalYear,
        beginFiscalYear: beginFiscalYear,
        endFiscalYear: endFiscalYear
      };
    },
    years() {
      let years = [];
      let begin = this.currentFiscalYear.beginFiscalYear;
      let end = this.currentFiscalYear.endFiscalYear.subtract(3, "year");

      while (begin.isSameOrAfter(end)) {
        years.push({
          begin: begin.format("YYYY/MM"),
          end: begin
            .subtract(1, "year")
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY/MM"),
          period: begin.format("YYYY"),
          value: begin.format("YYYY-MM-DD")
        });

        begin = begin.subtract(1, "year");
      }
      return years;
    }
  },
  data() {
    return {
      selectedYear: "",
      tableHeaders: [
        {
          text: "月",
          sortable: false,
          align: "center",
          value: "month_formatted"
        },
        {
          text: "売上実績",
          sortable: false,
          width: 150,
          align: "center",
          value: "sales",
          type: "money"
        },
        {
          text: "達成率 (%)",
          sortable: false,
          align: "center",
          value: "goal_percentage",
          type: "percentage"
        },
        {
          text: "現金売上",
          sortable: false,
          align: "center",
          value: "cash_sales",
          type: "money"
        },
        {
          text: "コスト",
          sortable: false,
          align: "center",
          value: "costs",
          type: "money"
        },
        {
          text: "損益",
          sortable: false,
          align: "center",
          value: "profit_or_loss",
          type: "money"
        },
        {
          text: "食材原価率",
          sortable: false,
          align: "center",
          value: "food_cost_percentage",
          extraValue: "foodstuff_cost",
          type: "money-percentage"
        },
        {
          text: "人件費率",
          sortable: false,
          align: "center",
          value: "labor_cost_percentage",
          extraValue: "labor_cost",
          type: "money-percentage"
        },
        {
          text: "客単価",
          sortable: false,
          align: "center",
          value: "average_customer_spend",
          type: "money"
        },
        { text: "", sortable: false, align: "center" }
      ]
    };
  },
  mounted() {
    this.selectedYear = this.currentFiscalYear.beginFiscalYear.format(
      "YYYY-MM-DD"
    );
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      let date = dayjs(this.selectedYear, "YYYY/MM").date(1);
      let month = date.format("YYYY-MM-DD");
      await this.$store.dispatch("SHOP_MONTHLY_REPORT_ALL", {
        shop_id: this.$route.params.shop_id,
        params: {
          month: month
        }
      });
    },
    showValue(header, item, key = "value") {
      return item[header[key]] ?? "--";
    },
    getFormattedDate(date) {
      return dayjs(date).format("YYYY-MM");
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
